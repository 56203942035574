import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './pages/layout';
import Index from './pages/index';
import Channel from './pages/channel';
import Playlist from './pages/playlist';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Index />} />
          <Route path="/channel/:id" element={<Channel />} />
          <Route path="/playlist/:id" element={<Playlist />} />
          <Route path="/:id" element={<Index />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
