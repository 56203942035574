import React from 'react';

//import { Link } from 'react-router-dom';
///import { Navigate } from 'react-router-dom';
//import jsPDF from 'jspdf';

import { useParams } from 'react-router';
//import html2canvas from 'html2canvas';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Index extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            src: `https://www.youtube.com/embed/AKDxROfsYLE?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`
        }
        this.src = `https://www.youtube.com/embed/AKDxROfsYLE?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`;
        console.log();
        if(this.props.params !==  undefined){
            this.setState({
                src: `https://www.youtube.com/embed/${this.props.params.id}?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`
            });
            this.src = `https://www.youtube.com/embed/${this.props.params.id}?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`;
        }
    }

    componentDidMount(){
        
    }

    render(){
        return(
            <>
                <div className="player">
                <iframe src={this.src} title="YouTube video player" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
            </>
        )
    }
}

export default withParams(Index);