import * as React from 'react';
import { Outlet } from 'react-router-dom';

class Layout extends React.Component {
  constructor (props) {  
    super(props);
    console.log(this.props)
  }

  render() {
    
    return (
      <>
        <Outlet />
      </>
    );
  }
}
export default Layout;
