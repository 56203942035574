import React from 'react';

//import { Link } from 'react-router-dom';
///import { Navigate } from 'react-router-dom';
//import jsPDF from 'jspdf';

import { useParams } from 'react-router';
//import html2canvas from 'html2canvas';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class channel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            iframe: <>Loading</>,
            list: <></>
        }
        
        if(this.props.params !==  undefined){
            this.setState({
                src: `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.props.params.id}&maxResults=1000&order=date&type=video&key=AIzaSyDnlXXzXbNVXBMrZAFhUnh0EdlHZqA81qA`
            });
            this.src = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.props.params.id}&maxResults=1000&order=date&type=video&key=AIzaSyDnlXXzXbNVXBMrZAFhUnh0EdlHZqA81qA`;
        }
        
    }

    async componentDidMount(){
        if(this.props.params !==  undefined){
            await this.fetchChannel(this.props.params.id);
           // this.src = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.props.params.id}&maxResults=100&order=date&type=video&key=AIzaSyDnlXXzXbNVXBMrZAFhUnh0EdlHZqA81qA`;
        }
    }

    playVideo = async (e) => {
        let vid = typeof(e) === 'string' ? e : e.target.getAttribute('id');
        console.log(e.target);
        console.log(vid);
        this.setState({
            iframe: <iframe src={`https://www.youtube.com/embed/${vid}?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`} title="YouTube Video Player" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        })
    }

    fetchChannel = async (channelid) => {
        let url = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelid}&maxResults=1000&order=date&type=video&key=AIzaSyDnlXXzXbNVXBMrZAFhUnh0EdlHZqA81qA`;
        let resp = await fetch(url);
        if(resp.ok){
            resp = await resp.json();
            if(Array.isArray(resp.items) && resp.items.length > 0){
                let list = resp.items.map((x, i)=>{
                    console.log(x);
                    return <li key={i} onClick={this.playVideo} id={x.id.videoId}>
                        <img src={x.snippet.thumbnails.medium.url} id={x.id.videoId} alt={x.snippet.title} title={x.snippet.title} className='thumb' />
                        <div className='video-title' id={x.id.videoId}>{x.snippet.title}</div>
                        </li>
                })
                this.setState({
                    iframe: <iframe src={`https://www.youtube.com/embed/${resp.items[0].id.videoId}?rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1&autoplay=1`} title="YouTube video player" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>,
                    list: list
                })
            }
        }
        console.log(resp)
    }


    render(){
        return(
            <>
                <div className="player">
                {this.state.iframe}
                </div>
                <div className='video-list'>
                    <ul>
                        {this.state.list}
                    </ul>
                </div>
            </>
        )
    }
}

export default withParams(channel);